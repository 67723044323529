
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { RegisteredTradeScrapingObject } from '@/store/modules/RegisteredTrades/RegisteredTradesScrapingModule';
  import Multiselect from '@vueform/multiselect';

  export default defineComponent({
    name: 'registered-trades-scraping-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const descriptionAr = ref('');
      const descriptionEn = ref('');
      const notesEn = ref('');
      const notesAr = ref('');
      const selectedNote = ref<number>(0);
      selectedNote.value = 23;

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('registeredTradesUpdating'), [
          translate('registeredTrades'),
        ]);
      });

      await store.dispatch(Actions.GET_ALL_AREAS_LIST, {
        type: 'registeredTrades',
      });
      const selectedArea = ref<number>(0);
      const selectedPropertyType = ref<number>(0);
      // await store.dispatch(Actions.GET_ALL_PROPERTY_TYPES);
      const { data } = await store.dispatch(
        Actions.GET_REGISTERED_TRADE_SCRAPING,
        route.params.id
      );

      const updatePricePerSqrM = async () => {
        if (
          registeredTrade.value &&
          registeredTrade.value.size &&
          registeredTrade.value.salePrice
        ) {
          registeredTrade.value.pricePerM2 =
            Number(registeredTrade.value.salePrice) /
            Number(registeredTrade.value.size);
        }
      };

      const registeredTrade = ref<RegisteredTradeScrapingObject>(data.data);
      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const registeredTradeSchema = Yup.object().shape({
        // transactionDate: Yup.date()
        //   .required(() => translate('TRANSACTION_DATE_IS_REQUIRED_FIELD'))
        //   .label('transactionDate'),
        // transactionNumber: Yup.number()
        //   .required(() => translate('TRANSACTION_NUMBER_IS_REQUIRED_FIELD'))
        //   .label('transactionNumber'),
        // selectedArea: Yup.mixed().test(
        //   'is-area-selected',
        //   'Please select an area',
        //   function () {
        //     debugger;
        //     return this.parent.selectedArea !== null;
        //   }
        // ),
        size: Yup.number()
          .required(() => translate('SIZE_IS_REQUIRED_FIELD'))
          .label('size'),
        percentage: Yup.string()
          .required(() => translate('PERCENTAGE_IS_REQUIRED_FIELD'))
          .label('Percentage'),
        price: Yup.number()
          .required(() => translate('PRICE_IS_REQUIRED_FIELD'))
          .label('price'),
        pricePerM2: Yup.number()
          .required(() => translate('PRICE_PER_M2_IS_REQUIRED_FIELD'))
          .label('pricePerM2'),
        // areaId: Yup.number()
        //   .required(() => translate('AREA_IS_REQUIRED_FIELD'))
        //   .label('areaId'),
        // propertyTypeId: Yup.number()
        //   .required(() => translate('PROPERTY_TYPE_IS_REQUIRED_FIELD'))
        //   .label('propertyTypeId'),
      });

      const updateDesc = async (values) => {
        debugger;
        const selectedDesc = registeredDescs.value.find(
          (desc) => desc.id == values
        );
        descriptionAr.value = selectedDesc?.titleAr;
        descriptionEn.value = selectedDesc?.titleEn;
      };

      const updateNotes = async (values) => {
        debugger;
        const selectedNotes = registeredNotes.value.find(
          (desc) => desc.id == values
        );
        notesAr.value = selectedNotes?.titleAr;
        notesEn.value = selectedNotes?.titleEn;
      };

      const onSubmitUpdate = async (values) => {
        if (selectedArea.value == 0 || selectedPropertyType.value == 0) return;
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        values.id = registeredTrade.value?.id;
        values.areaId = selectedArea.value;
        values.propertyTypeId = selectedPropertyType.value;
        delete values.description;
        delete values.notes;

        await store.dispatch(Actions.CREATE_REGISTERED_TRADE_SCRAPING, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_REGISTERED_TRADE_SCRAPING'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'registered-trades-scraping-listing' });
          });
        }
      };
      store.dispatch(Actions.GET_ALL_AREAS_LIST, {
        type: 'registeredTrades',
      });
      store.dispatch(Actions.GET_ALL_PROPERTY_TYPE, {
        type: 'registeredTrades',
      });
      await store.dispatch(Actions.GET_REGISTERED_TRADES_ASSETS, 6);
      await store.dispatch(Actions.GET_REGISTERED_TRADES_ASSETS, 7);
      const registeredNotes = computed(() => store.getters.getRegisteredNotes);

      updateNotes(23);

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');
      const registeredDescs = computed(() => store.getters.getRegisteredDesc);
      return {
        updatePricePerSqrM,
        selectedNote,
        notesAr,
        notesEn,
        descriptionAr,
        descriptionEn,
        updateNotes,
        updateDesc,
        registeredDescs,
        registeredNotes,
        areas: computed(() => store.getters.allListAreas),
        propertyTypes: computed(() => store.getters.propertyTypesAllList),
        onSubmitUpdate,
        submitButton,
        translate,
        registeredTradeSchema,
        goBack,
        registeredTrade,
        can,
        selectedArea,
        selectedPropertyType,
      };
    },
  });
